// import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import { timeStatus, getCurrentDay } from "../../../utils/TimeUtils.js"


import './ButtonFormat.css'
import { Link } from 'react-router-dom';

const ButtonFormat = ({ restaurantInformation }) => {
    // Get name from json and change it so it can grab the info from assets
    const codeRestaurantName = restaurantInformation.name.replace(/[.'"]/g, '')
    const imageUrl = require(`../../../assets/restaurantItems/restaurantImages/${codeRestaurantName}.png`);
    const logoUrl = require(`../../../assets/restaurantItems/restaurantLogos/${codeRestaurantName}_Logo.png`)

    // let scheduleStatus = "Closed"
    const restaurantName = restaurantInformation.name.split("_").join(" ");
    // const currentTime = getCurrentDay(restaurantInformation.time_zone)


    // Get current day and schedule from the restaurants array
    // const currentRestaurantInfo = restaurantInformation.schedule_days.find(
    //     (x) => x.day === currentTime.currentDay
    // );

    // if (typeof currentRestaurantInfo !== "undefined") {
    //     scheduleStatus = timeStatus(
    //         currentRestaurantInfo.firstOpen,
    //         currentRestaurantInfo.firstClose,
    //         currentRestaurantInfo.secondOpen,
    //         currentRestaurantInfo.closeOvernight,
    //         currentTime.currentTime
    //     );
    // }

    return <Link className='buttonformat-wrapper' to={restaurantInformation.url}>
        <div className='buttonformat-image-wrapper'>
            <img src={imageUrl} alt={`${restaurantInformation.name} Restaurant`} className='buttonformat-image' />
            <img src={logoUrl} alt={`${restaurantInformation.name} Logo`} className="buttonformat-logo" />
        </div>
        <div className="buttonformat-information-wrapper">
            <h3 style={{ color: 'black' }}>{restaurantName}</h3>
            {/* <div className="buttonformat-schedule-wrapper" style={{ color: scheduleStatus === "Open" ? 'green' : 'red' }}>
                <AccessTimeIcon />
                <h4>{scheduleStatus}</h4>
            </div> */}
        </div>
    </Link>
}

export default ButtonFormat